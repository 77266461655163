.drawer {
  .paper {
    width: $drawer-width; }

  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 10px 10px 10px;

    .avatar {
      width: 125px;
      height: 125px;
      transition: .3s;

      &:hover {
        width: 150px;
        height: 150px; } }

    .name {
      color: $dark-color;
      font-family: 'PlayFair Display', sans-serif;
      font-size: 18px;
      letter-spacing: .5px;
      transition: .3s;
      margin: 30px 0;

      &:hover {
        color: $accent-color; } } }

  .lists {
    .list_item {
      color: $dark-color;
      padding-left: 30px;

      &.logout {
        background: rgba($accent-color, .2); } } } }
