@mixin article-text-style {
  color: rgba($dark-color, .9);
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  line-height: 28px; }

.article {
  display: flex;
  flex-direction: column;

  .header {
    .thumbnail {
      width: 100%;
      height: 425px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      background: rgba($dark-color, .05);

      .title {
        color: $dark-color;
        font-family: 'PlayFair Display', sans-serif;
        line-height: 34px; }

      .date {
        color: $accent-color;
        font-family: 'Rubik', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 40px;
        letter-spacing: 1.5px;
        opacity: .8; } } }

  .body {
    padding: 20px 0;

    .latest_article {}

    .content {
      .text_wrapper {
        padding-bottom: 20px;
        border-bottom: .5px solid rgba($dark-color, .5);

        .text {
          @include article-text-style; } }

      .footer_wrapper {
        padding-top: 20px; } } } }

.ck-editor, .article {
  @include article-text-style;

  blockquote {
    border-left: 4px solid rgba($accent-color, .75);
    padding: 2px 20px;
    margin: 24px 0;
    font-family: 'Playfair Display', sans-serif;
    background: rgba($accent-color, .03);
    font-size: 16px; }

  figure {
    margin: 0 !important;
    padding: 0 !important;

    img {
      width: 100%;
      height: auto;
      object-fit: cover; } } }

.ck-editor__editable_inline {
    height: 37.5vh; }
