.profile_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .thumbnail {
    display: flex;
    justify-content: center;

    .avatar {
      width: 100%;
      max-width: 325px;
      height: auto;
      object-fit: cover;
      border-radius: 0; } }

  .text {
    .header {
      .primary {
        color: $dark-color;
        font-family: 'PlayFair Display', sans-serif; }

      .secondary {
        color: $accent-color;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: .75px;
        line-height: 60px; } }

    .body {
      .primary {
        color: $dark-color;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: .5px; }

      .secondary {
        color: $dark-color;
        font-family: 'Poppins', sans-serif;
        line-height: 36px; } } } }
