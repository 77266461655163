.dialog-form {
  .swith-text {
    color: $dark-color;
    font-family: 'Poppins', sans-serif; }

  .input-file {
    display: flex;
    flex-direction: row;

    .file-name-input {
      flex: 1;
      margin-left: 10px; } } }
