.article_card {
  margin-bottom: 20px !important;

  .media {
    width: 100%;
    height: 300px;
    overflow: hidden;

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .5s;

      &:hover {
        opacity: .75;
        transform: scale(1.05); } } }

  .title {
    color: $dark-color;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .75px;
    cursor: pointer;
    transition: .3s;
    margin: 15px 0 10px 0;

    &:hover {
      color: $accent-color; } }

  .date {
    color: $accent-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin: 0; } }
