.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 89.1vh;

  .header {
    .text {
      color: $dark-color;
      font-family: 'Poppins'; } }

  .body {
    margin: 20px 0 30px 0;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      margin: 10px 0;
      width: 110px;
      height: 110px;
      object-fit: cover; }

    .input-field {
      width: 100%;
      margin-top: 20px; } }

  .footer {
    width: 250px;
    align-items: flex-start;

    .button {
      width: 100%; } } }
