.exception {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
  color: $dark-color;

  &.with-drawer {
    height: 89vh; }

  .title {
    font-size: 56px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0; }

  .subtitle {
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin: 10px 0 30px 0;
    padding: 0; } }
