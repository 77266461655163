.table_component {
  min-width: 900px;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }

  .thead {
    background: rgba($dark-color, .05);

    .cell {
      font-size: 14px;
      color: $accent-color;
      line-height: 30px; } }

  .tbody {
    .cell {
      color: $dark-color;
      line-height: 30px; } }

  .thumbnail {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  a {
    text-decoration: none; }

  .empty-data {
    color: $red;
    font-family: 'Raleway', sans-serif;
    font-size: 14px; } }
