// Responsive
$responsive-xs: 0px;
$responsive-sm: 600px;
$responsive-md: 960px;
$responsive-lg: 1280px;
$responsive-xl: 1920px;

// Drawer
$drawer-width: 300px;

// AppBar
$appbar-height: 64px;
