@import "variables/index";
@import "components/index";
@import "routes/index";

body {
  background: $light-color;
  font-family: 'Roboto', sans-serif; }

main {
  padding: 30px;
  margin-left: $drawer-width;
  min-height: 89.1vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.link {
  text-decoration: none; }

::selection {
  background: $accent-color;
  color: $light-color; }


@media screen and (max-width: $responsive-md - 1px) {
  main {
    padding: 17.5px;
    margin-left: 0;
    margin-top: $appbar-height; } }


@media screen and (max-width: $responsive-sm - 1px) {
  main {
    padding: 10px; } }
