.expansion_panel {
  box-shadow: none !important;
  border: 1px solid rgba($accent-color, .25);
  border-radius: 5px !important;
  padding: 10px 5px;
  margin-top: 16px;

  &::before {
    height: 0px !important; }

  .summary {
    font-family: 'Poppins', sans-serif;
    letter-spacing: .25px;
    color: $dark-color; }

  .details {
    display: block;
    overflow: auto;

    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; } } }
