.snackbar {
  .variant {
    &.error {
      background: $red; }
    &.warning {
      background: $yellow; }
    &.info {
      background: $blue; }
    &.success {
      background: $green; } }

  .message {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px; } } }
